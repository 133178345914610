/* eslint-disable */

import React, { Component } from 'react';

class ChatImage extends Component {
  render() {
    return (
      <a
        href={this.props.message.attachment.url}
        target="_blank"
        className="chat-img-container"
        rel="noreferrer"
      >
        <div
          className="chat-img"
          style={{
            backgroundImage: `url(${this.props.message.attachment.url})`,
          }}
        />
      </a>
    );
  }
}

ChatImage.displayName = 'ChatImage';
ChatImage.propTypes = {};
ChatImage.defaultProps = {
  message: {
    url: '',
  },
};

export default ChatImage;
