/* eslint-disable */

import React from 'react';
import ReactDOM from 'react-dom';
import 'idempotent-babel-polyfill';
import { Provider } from 'react-redux';
import Widget from './components/Widget';
import ChatStore from './stores/ChatStore';
import { changeColorScheme, changeFontFamily } from './utils/ThemeSelection';
import './index.css';


window.initializeChat = config => {
  let widget = document.getElementById('grs-chat-widget');

  if (!widget) {
    widget = document.createElement('div');
    widget.id = 'grs-chat-widget';
    document.body.appendChild(widget);
  }

  changeColorScheme( config.COLOR_SCHEME || localStorage.getItem('chat-color-scheme') );
  changeFontFamily( config.FONT_FAMILY || localStorage.getItem('chat-font-family') );

  // Render the main component into the dom
  ReactDOM.render(
    <Provider store={ChatStore}>
      <Widget config={config} />
    </Provider>,
    widget
  );
}