export function isAgent(nick) {
  return nick.startsWith('agent:');
}

export function isTrigger(nick) {
  return nick.startsWith('agent:trigger');
}

export * from './PersistentStorage';

export function log(msg) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(msg);
  }
}
