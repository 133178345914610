import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import zChat from '../../vendor/web-sdk';
import CardContainer from './CardContainer';
import MessageSvg from './MessageSvg';
import ActionButton from './ActionButton';
import countryList from '../constants/countries';
// import departments from '../constants/departments';
import countriesCode from '../constants/countriesCode';
import SecuritySvg from './SecuritySvg';

// eslint-disable-next-line space-before-function-paren
export default function PreChatForm(props) {
  const [sent, setSent] = useState(false);
  const [step, setStep] = useState('form');
  const [streetAddress, setStreetAddress] = useState('');
  const [streetError, setStreetError] = useState(false);
  const [streetErrorMessage, setStreetErrorMessage] = useState('The street address is required');
  const [answer, setAnswer] = useState('');
  const [trySecurityQuestion, setTrySecurityQuestion] = useState(false);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const [isDepartmentOffline, setIsDepartmentOffline] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { config } = props;
  const { onFileUpload } = props;

  let {
    countryCode = countriesCode.filter(
      (item) => item.code === config.DETECTED_COUNTRY
    )[0],
  } = useForm();

  if (countryCode) {
    countryCode = countryCode.dial_code;
  } else {
    countryCode = '';
  }
  const [dialCode, setResult] = useState(countryCode);
  const countryOfBirth = watch('countryOfBirth');
  const numberOfapplicants = watch('numberOfapplicants');
  const lengthOfStay = watch('lengthOfStay');
  const detectedCountry = config.DETECTED_COUNTRY;
  const dispatch = useDispatch();

  useEffect(() => {
    // if detected user country is not on the list, then remove chat
    const countryFound = config.ALLOWED_COUNTRIES.indexOf(detectedCountry);
    // eslint-disable-next-line operator-linebreak
    const allowAllCountries =
      (config.ALLOW_ALL_COUNTRIES === undefined
        ? true
        : config.ALLOW_ALL_COUNTRIES) || false;

    if (countryFound === -1 && allowAllCountries === false) {
      document.querySelector('#grs-chat-widget').remove();
    }

    // find user's country from the list

    const department = zChat.getDepartment(config.DEFAULT_DEPARTMENT);
    setIsDepartmentOffline(department && department.status !== 'online');

    // Set departments to SALES

    if (department && department.id !== undefined) {
      if (department.status !== 'online' && !!config.HIDE_WHEN_NO_AGENT_ONLINE) {
        document.querySelector('#grs-chat-widget').remove();
      }

      zChat.setVisitorDefaultDepartment(parseInt(department.id, 10), () => { });
    }
  }, [
    config.ALLOWED_COUNTRIES,
    config.ALLOW_ALL_COUNTRIES,
    config.DEFAULT_DEPARTMENT,
    config.HIDE_WHEN_NO_AGENT_ONLINE,
    detectedCountry
  ]);

  const initFile = (e) => {
    setFile(e);
    setFileName(e.target.files[0].name);
  };

  function loadChat(toBeSetDepartmentId, paid = false) {
    const { name, email, phone, reasonOfContact } = JSON.parse(localStorage.getItem('data'));

    // Country of Birth: ${countryOfBirth || '--'}
    // Total Number of Applicants: ${numberOfapplicants || '--'}
    // Length Of Stay: ${lengthOfStay || '--'}
    let allInfo = `
      Full Name: ${name || '--'}
      Email: ${email || '--'}
      Phone Number: ${phone || '--'}
      How may we help?: ${reasonOfContact || '--'}
      `;

    if (localStorage.getItem('verified') === 'true') {
      allInfo += `Verified: VERIFIED
      ${localStorage.getItem('question')}: ${localStorage.getItem('answer')}
      `;
    } else {
      allInfo += `Verified: NOT VERIFIED
      `;
    }

    zChat.setVisitorDefaultDepartment(
      parseInt(toBeSetDepartmentId, 10), (error) => { console.log(`Error : ${error}`); }
    );

    if (paid) {
      zChat.addTag('paid');
      allInfo += `Paid customer: Yes
      `;
    } else {
      zChat.addTag('not_paid');
      allInfo += `Paid customer: No
      `;
    }

    zChat.setVisitorInfo({ display_name: name, email },
      (err) => {
        if (err) return;
        zChat.sendChatMsg(allInfo, (err2) => {
          if (err2) console.log('Error sending message');
        });
      }
    );
  }

  function goWithFailedVerification() {
    // eslint-disable-next-line no-alert
    alert(
      "You didn't pass the pre-verification step. The agent that will serve you will ask for more verification questions"
    );
    localStorage.setItem('verified', false);
    loadChat(config.GROUPS_LIST.LEVEL_ONE_TEAM, true);
  }

  function errorOccured() {
    // eslint-disable-next-line no-alert
    // alert(
    //   'Something went wrong when trying to validate PIN. Please contact support.'
    // );
    localStorage.setItem('verified', false);
    loadChat(config.GROUPS_LIST.LEVEL_ONE_TEAM, true);
  }

  function goToNonPaid() {
    localStorage.setItem('verified', false);
    loadChat(config.GROUPS_LIST.SALES_TEAM, false);
  }

  function verifyStreetAddress() {
    const { email } = JSON.parse(localStorage.getItem('data'));
    if (streetAddress.length === 0) {
      setStreetError(true);
      setStreetErrorMessage('The street address is required.');
      return;
    }
    fetch(`${config.BASE_URL}/api/chat/verify/street-address`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        mode: 'cors',
      },
      body: JSON.stringify({ email, streetAddress })
    })
      .then((response) => {
        const { status } = response;
        if (status === 404) {
          setStreetErrorMessage('The street address seems invalid, could you please try again?');
          setStreetError(true);
        } else if (status === 200) {
          localStorage.setItem('verified', true);
          localStorage.setItem('question', 'Street Address');
          localStorage.setItem('answer', streetAddress);
          loadChat(config.GROUPS_LIST.LEVEL_ONE_TEAM, true);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        errorOccured();
      });
  }

  function verifyQuestion() {
    const { email } = JSON.parse(localStorage.getItem('data'));
    fetch(`${config.BASE_URL}/api/chat/verify/question`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        mode: 'cors',
      },
      body: JSON.stringify({ email, answer })
    })
      .then((response) => {
        const { status } = response;
        if (status === 404) {
          goWithFailedVerification();
        } else if (status === 200) {
          localStorage.setItem('verified', true);
          localStorage.setItem('question', `Security Question : ${localStorage.getItem('securityQuestion')}`);
          localStorage.setItem('answer', answer);
          loadChat(config.GROUPS_LIST.LEVEL_ONE_TEAM, true);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        errorOccured();
      });
  }

  const onSubmit = (data) => {
    localStorage.removeItem('data');
    localStorage.removeItem('verified');

    const { email } = data;
    localStorage.setItem('data', JSON.stringify(data));

    fetch(`${config.BASE_URL}/api/chat/verify/email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        mode: 'cors',
      },
      body: JSON.stringify({ email })
    })
      // eslint-disable-next-line consistent-return
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return response.json();
        }
      })
      .then((response) => {
        if (response.status === 500 || response.isValid === true) {
          goToNonPaid();
        } else if (response.isValid === false) {
          // eslint-disable-next-line no-alert, no-restricted-globals
          if (response.suggestion && confirm(`Hey!, we detected a possible typo on your email \n\n Do you want to use the suggested email: ${response.suggestion}!`) === true) {
            // eslint-disable-next-line no-param-reassign
            data.email = response.suggestion;
            onSubmit(data);
          } else {
            // eslint-disable-next-line no-alert, no-restricted-globals, no-lonely-if
            if (confirm('Your email address seems incorrect, but you can proceed if it\'s accurate!') === true) {
              goToNonPaid();
            }
          }
        } else if (response.hasSecurityVerification === false) {
          localStorage.setItem('verified', false);
          loadChat(config.GROUPS_LIST.LEVEL_ONE_TEAM, true);
        } else {
          localStorage.setItem('securityQuestion', response.securityQuestion);
          setStep('security');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        goToNonPaid();
      })
      .finally(() => {
        setTimeout(() => {
          onFileUpload(file, true);
        }, 3000);
      });

    setSent(true);
    dispatch({
      type: 'synthetic',
      detail: {
        type: 'visitor_send_msg',
        msg: '',
      },
    });
  };

  return (

    <div>
      {
        step === 'form' && (
          <CardContainer
            title="Introduce yourself!"
            addClass="offline-card"
            contentAddClass={sent ? 'sent' : ''}
            icon={<MessageSvg />}
          >
            <form
              key="not-sent"
              className="offline-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              {config.SHOW_OFFLINE_MSG && isDepartmentOffline && (
                <div>
                  <p>
                    <b>
                      There are no available online agents now. Please leave your
                      contact info below, we will get back to you as soon as possible.
                      Thanks!
                    </b>
                  </p>
                </div>
              )}
              <div className="content">
                {config.FIELDS.includes('NAME') && (
                  <div className="section">
                    <label className="label">Full Name</label>
                    <input
                      className={errors.name ? 'invalid-input' : ''}
                      {...register('name', {
                        required: true,
                        pattern: /^(?! )[A-Za-z\s]+$/i,
                      })}
                    />
                    {errors.name?.type === 'required' && (
                      <div className="text-red">Name is required</div>
                    )}
                    {errors.name?.type === 'pattern' && (
                      <div className="text-red">Please enter a valid name</div>
                    )}
                  </div>
                )}

                {config.FIELDS.includes('EMAIL') && (
                  <div className="section">
                    <label className="label">Email</label>
                    <input
                      className={errors.email ? 'invalid-input' : ''}
                      {...register('email', {
                        required: true,
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />
                    {errors.email?.type === 'required' && (
                      <div className="text-red">Email is required</div>
                    )}
                    {errors.email?.type === 'pattern' && (
                      <div className="text-red">Enter a valid email</div>
                    )}
                  </div>
                )}
                {config.FIELDS.includes('PHONE') && (
                  <div className="section">
                    <label className="label">Phone Number</label>
                    <input
                      className={errors.phone ? 'invalid-input' : ''}
                      {...register('phone', {
                        required: true,
                      })}
                      value={dialCode}
                      onChange={(e) => setResult(`${e.target.value}`)}
                    />
                    {errors.phone?.type === 'required' && (
                      <div className="text-red">Phone is required</div>
                    )}
                  </div>
                )}

                {config.FIELDS.includes('COUNTRY_OF_BIRTH') && (
                  <div className="section">
                    <label className="label">Country of Birth</label>
                    <select
                      {...register('countryOfBirth', {
                        required: true,
                      })}
                      className={errors.countryOfBirth ? 'invalid-input' : ''}
                    >
                      {!countryOfBirth && <option value="">Select a country</option>}
                      {Object.entries(countryList)
                        .sort(([, country1], [, country2]) =>
                          country1.localeCompare(country2)
                        )
                        .map(([code, country]) => (
                          <option key={`c1_${code}`} value={`${country} (${code})`}>
                            {country}
                          </option>
                        ))}
                    </select>
                    {errors.countryOfBirth?.type === 'required' && (
                      <div className="text-red">Country of Birth is required</div>
                    )}
                  </div>
                )}

                {config.FIELDS.includes('APPLICANTS_NUMBER') && (
                  <div className="section">
                    <label className="label">Total Number of Applicants</label>
                    <select
                      {...register('numberOfapplicants', {
                        required: true,
                      })}
                      className={errors.numberOfapplicants ? 'invalid-input' : ''}
                    >
                      {!numberOfapplicants && <option value="">Select</option>}
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10+</option>
                    </select>
                    {errors.numberOfapplicants?.type === 'required' && (
                      <div className="text-red">Number of Applicants is required</div>
                    )}
                  </div>
                )}

                {config.FIELDS.includes('LENGTH_OF_STAY') && (
                  <div className="section">
                    <label className="label">Length of Stay</label>
                    <select
                      {...register('lengthOfStay', {
                        required: true,
                      })}
                      className={errors.lengthOfStay ? 'invalid-input' : ''}
                    >
                      {!lengthOfStay && <option value="">Select</option>}
                      <option>Less than 1 week</option>
                      <option>1 week or more (and less than a month)</option>
                      <option>1 month or more (and less than 3 months)</option>
                      <option>3 months or more (and less than 6 months)</option>
                      <option>6 months or more (and less than 1 year)</option>
                      <option>1 year or more</option>
                      <option>Not sure</option>
                    </select>
                    {errors.lengthOfStay?.type === 'required' && (
                      <div className="text-red">Length of Stay is required</div>
                    )}
                  </div>
                )}

                <div className="section">
                  <label className="label">Chat With an Expert</label>
                  <textarea
                    className={errors.reasonOfContact ? 'invalid-input' : ''}
                    {...register('reasonOfContact', {
                      required: true,
                      pattern: /^(?:(?! ).*)|(?:\s?[^ ]+.*)$/i,
                    })}
                  />
                  {errors.reasonOfContact?.type === 'required' && (
                    <div className="text-red">Reason of Contact is required</div>
                  )}

                  {errors.reasonOfContact?.type === 'pattern' && (
                    <div className="text-red">Please enter a reason of contact</div>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor="file" className="tw-w-full tw-flex tw-justify-start tw-items-center">
                  <label htmlFor="file" className="tw-mr-5 tw-p-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-w-8 tw-h-8">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                    </svg>
                  </label>
                  {fileName !== '' && <span>{fileName}</span>}
                </label>
                <input id="file" type="file" onChange={initFile} className="tw-hidden" />
              </div>
              {true && (
                <div className="button-container">
                  <ActionButton addClass="button-send" label="Send" />
                </div>
              )}
            </form>
          </CardContainer>
        )
      }

      {step === 'security' && (
        <CardContainer
          title="Pre-verification step"
          addClass="offline-card"
          contentAddClass={sent ? 'sent' : ''}
          icon={<SecuritySvg />}
        >
          <div className="tw-font-semibold tw-text-center">
            In order for us to keep your personal information secure,
            you are required to authenticate your identity
            by providing your street address in the communication that will follow.
          </div>
          <div>

            <div
              key="not-sent"
              className="offline-form mt-10"
            >
              <div className="content">
                <div className="section">
                  <label className="label">Street Address: </label>
                  <input
                    className={errors.street ? 'invalid-input' : ''}
                    value={streetAddress.value}
                    onChange={(event) => setStreetAddress(event.target.value)}
                    disabled={trySecurityQuestion}
                  />
                  {streetError && !trySecurityQuestion && (
                    <div className="text-red tw-my-1">{streetErrorMessage}</div>
                  )}
                  <div className="tw-flex tw-items-center tw-justify-start tw-mb-4 tw-text-left">
                    <input
                      id="pin-forgot"
                      type="checkbox"
                      style={{ width: '15px', height: '15px' }}
                      checked={trySecurityQuestion}
                      onChange={() => { setTrySecurityQuestion(!trySecurityQuestion); }}
                    />
                    <label htmlFor="pin-forgot" className="tw-ml-2 tw-text-sm tw-font-medium tw-text-gray-900 ">I forgot the street address</label>
                  </div>
                </div>

                {trySecurityQuestion && (
                  <div className="section">
                    <div className="tw-font-semibold tw-text-center tw-mt-5 tw-mb-3">
                      In case you forgot your street address, please answer the security question.
                    </div>

                    <label className="label">
                      {' '}
                      {localStorage.getItem('securityQuestion')}
                      {' '}
                    </label>
                    <input
                      value={answer.value}
                      onChange={(event) => setAnswer(event.target.value)}
                    />
                    <div className="tw-flex tw-items-center tw-justify-start tw-mb-4 tw-text-left tw-mt-2">
                      <input
                        id="pin-forgot"
                        type="checkbox"
                        style={{ width: '15px', height: '15px' }}
                        onChange={() => { goWithFailedVerification(); }}
                      />
                      <label htmlFor="pin-forgot" className="tw-ml-2 tw-text-sm tw-font-medium tw-text-gray-900">I forgot the SQ</label>
                    </div>
                  </div>
                )}
              </div>
              <div className="button-container">
                <ActionButton addClass="button-send" label="Send" type="button" onClick={() => (trySecurityQuestion ? verifyQuestion() : verifyStreetAddress())} />
              </div>
            </div>

          </div>
        </CardContainer>
      )}
    </div>
  );
}
