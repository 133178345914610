/* eslint-disable */

import React, { Component } from 'react';
import SendButton from 'components/SendButton';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: ''
    };
    this.initFile = this.initFile.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
  }

  getRawInput() {
    return this.refs.input;
  }

  onDragOver(event) {
    this.setState({ dragover: true });
    event.preventDefault();

    event.dataTransfer.dropEffect = 'copy';
  }

  onDrop(event) {
    this.setState({ dragover: false });
    this.props.onFileUpload(event);
  }

  onDragLeave() {
    this.setState({ dragover: false });
  }

  initFile(e) {
    this.props.onFileUpload(e, true);
    this.fileName = e.target.files[0].name;
  };

  render() {
    const class_name = [
      'input-container',
      this.props.addClass,
      this.state.dragover ? 'drag-drop-zone' : '',
    ].join(' ');

    return (
      <div
        className={class_name}
        onDrop={this.onDrop}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
      >
        <form className="input-form" onSubmit={this.props.onSubmit}>
          <div className="tw-w-[88%]">
            <input
              className="input tw-w-full !tw-bg-white !tw-text-[#000e28]"
              ref="input"
              placeholder="Enter message here"
              onChange={this.props.onChange}
              onFocus={this.props.onFocus}
            />
          </div>
          <div>
            <label htmlFor="file" className="tw-mr-5 tw-flex tw-justify-start tw-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-w-8 tw-h-8">
                <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
              </svg>
            </label>
            <input
              id="file"
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              onChange={this.initFile}
              className="tw-hidden"
            />
          </div>
          <div>
            <SendButton onClick={this.props.onSubmit} />
          </div>
        </form>
      </div>
    );
  }
}

Input.displayName = 'Input';
Input.propTypes = {};

export default Input;
