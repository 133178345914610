/* eslint-disable */

import React, { Component } from 'react';

class ActionButton extends Component {
  render() {
    return (
      <button
        className={`action-button ${this.props.addClass}`}
        onClick={this.props.onClick}
      >
        {this.props.label}
      </button>
    );
  }
}

ActionButton.displayName = 'ActionButton';
ActionButton.propTypes = {};

export default ActionButton;
