/* eslint-disable */

export const changeColorScheme = (color) => {

    if (color === 'dark') {
        localStorage.setItem('chat-color-scheme', 'dark');
        document.documentElement.style.setProperty('--chat-theme-color', '#000E28');
    } else {
        localStorage.setItem('chat-color-scheme', 'light');
        document.documentElement.style.setProperty('--chat-theme-color', '#0067FF');
    }
    
}

export const changeFontFamily = (font) => {

    if (font === 'Roboto') {
        localStorage.setItem('chat-font-family', 'Roboto');
        document.documentElement.style.setProperty('--chat-font-family', 'Roboto');
    } else {
        localStorage.setItem('chat-font-family', 'Archivo');
        document.documentElement.style.setProperty('--chat-font-family', 'Archivo');
    }
}